import React, { useState, useEffect } from 'react';
import { Modal, RadioGroup, FormControlLabel, Radio, Button, Box, Stack, Pagination, Typography } from '@mui/material';

const LinkCode = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        console.log("A" + props.selectedProcCodes);
    }, [props.selectedProcCodes]);

    const handleClose = () => {
        props.setOpen(false);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Calculate the items to display based on currentPage and itemsPerPage
    const paginatedItems = props.procList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <Modal
                open={props.open} // Use props.open if provided, otherwise use local state
                onClose={handleClose}
                aria-labelledby="link-code-modal"
                aria-describedby="link-code-modal-description"
            >
                <Box 
                    sx ={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        height: '80vh',
                        overflowY: 'auto'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <Box>
                            <Typography variant="h5" id="link-code-modal" sx={{ mb: 1 }}>
                                Link Code
                            </Typography>
                            <Typography variant="body1" id="link-code-modal-description" sx={{ mb: 2 }}>
                                Procedure Code with Task type - {props.taskType?.title}
                            </Typography>
                            <RadioGroup
                                aria-label="procedure-code"
                                name="procedure-code"
                                value={props.selectedProcCodes? props.selectedProcCodes[props.taskType.id]: ''}
                                onChange={(e) => props.setSelectedProcCodes({ ...props.selectedProcCodes, [props.taskType.id]: e.target.value }) }
                            >
                                {paginatedItems.map((proc) => (
                                    <FormControlLabel
                                        key={proc.procCode_id}
                                        value={proc.procCode_id}
                                        control={<Radio />}
                                        label={proc.procCode_description}
                                    />
                                ))}
                            </RadioGroup>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Pagination
                                count={Math.ceil(props.procList.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                sx={{ mt: 2 }}
                            >
                                Link
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default LinkCode;
