import React, { useState, useEffect } from 'react';
import { Modal, FormControlLabel, Checkbox, Button, Box, Stack, Pagination, Typography } from '@mui/material';

const LinkForm = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleClose = () => {
        props.setOpen(false);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCheckboxChange = (event) => {
        const value = parseInt(event.target.value);
        const taskTypeId = props.taskType.id;

        console.log(props.selectedForms[taskTypeId], value);

        if (event.target.checked) {
            props.setSelectedForms({ ...props.selectedForms, [taskTypeId]: [...(props.selectedForms[taskTypeId] || []), value] });
        } else {
            props.setSelectedForms({ ...props.selectedForms, [taskTypeId]: (props.selectedForms[taskTypeId] || []).filter((formId) => formId !== value) });
        }
        
    };

    // Calculate the items to display based on currentPage and itemsPerPage
    const paginatedItems = props.formList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <Modal
                open={props.open} // Use props.open if provided, otherwise use local state
                onClose={handleClose}
                aria-labelledby="link-form-modal"
                aria-describedby="link-form-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                        height: '80vh',
                        overflowY: 'auto'
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <Box>
                            <Typography variant="h5" id="link-form-modal" sx={{ mb: 1 }}>
                                Link Form
                            </Typography>
                            <Typography variant="body1" id="link-form-modal-description" sx={{ mb: 2 }}>
                                Form with Task type - {props.taskType?.title}
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {paginatedItems.map((form) => (
                                    <FormControlLabel
                                        key={form.form_id}
                                        control={
                                            <Checkbox
                                                checked={props.selectedForms[props.taskType.id]?.includes(form.form_id)}
                                                onChange={handleCheckboxChange}
                                                value={form.form_id}
                                            />
                                        }
                                        label={form.form_name}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <Pagination
                                count={Math.ceil(props.formList.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                sx={{ mt: 2 }}
                            >
                                Link
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default LinkForm;
