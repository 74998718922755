import React, { useEffect, useState } from 'react';
import { Form, FormBuilder } from '@formio/react';
import { Button as MUIButton, Typography } from '@mui/material';
import { Button } from "react-bootstrap";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { APIURL } from '../../../config';

export default function FormLibrary(props) {
    const [formSchema, setFormSchema] = useState('');
    const [formList, setFormList] = useState([]);
    const [subscribedForms, setSubscribedForms] = useState([]);
    const [publicForms, setPublicForms] = useState([]);
    const [formID, setFormID] = useState('');
    const [formName, setFormName] = useState('');
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');
    const [successAlert, setSuccessAlert] = useState('');

    let editFormSchema = {};

    useEffect(async () => {
        const forms = await axios.get(`${APIURL}/task_form/forms`);
        const subscribedForms = await axios.get(`${APIURL}/task_form/subscribed?company_id=${props.comp_id}`)

        let formio_forms = forms.data.filter((form) => form.form_company === props.comp_id && !form.public_form);
        let subscribed_forms = subscribedForms.data;
        formio_forms = formio_forms.filter(form => !subscribed_forms.some(subForm => subForm.form_id === form.form_id));
        let public_forms = forms.data.filter((form) => form.public_form);

        setFormList(formio_forms);
        setSubscribedForms(subscribed_forms);
        setPublicForms(public_forms);
        setLoading(false);
    }, []);

    const handleFormSelect = (form) => {
        editFormSchema = JSON.parse(form.json_schema)
        setFormSchema(form.json_schema);
        setIsEdit(false);
        setFormID(form.form_id);
        setFormName(form.form_name)
    }

    const handleFormSchemaChange = (schema) => {
        schema.display = 'form';
        editFormSchema = schema;
    }

    const editForm = async () => {
        await axios.put(APIURL + `/task_form/formBuilder`, {
            form_id: formID,
            json_schema: editFormSchema,
        })
            .then(function (response) {
                setSuccessAlert('Form successfully edited. Please reload!')
            })
            .catch(function (error) {
                console.log(error);
                setErrorAlert('An error occured while trying to edit the form')
            });

        setIsEdit(false);
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorAlert('');
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessAlert('');
    }

    return (
        <div style={{ marginRight: '40px' }}>
            <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 10,
                }}
            >
                Form Library
            </Typography>
            <div style={{ maxHeight: '33vh' }}>
                {!!loading && (
                    <div className="d-flex flex-row w-100 justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!loading &&
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div style={{ width: '33%' }}>
                            <h5 style={{ marginBottom: 10 }}>Your Forms</h5>
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {formList.map((form) => (
                                    <div key={form.form_id}>
                                        <MUIButton onClick={() => { handleFormSelect(form); setIsEditable(true); }}>
                                            {form.form_name}
                                        </MUIButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: '33%', borderLeft: '1px solid #ccc', paddingLeft: '10px' }}>
                            <h5 style={{ marginBottom: 10 }}>Community Forms</h5>
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {publicForms.map((form) => (
                                    <div key={form.form_id}>
                                        <MUIButton onClick={() => { handleFormSelect(form); setIsEditable(false); }}>
                                            {form.form_name}
                                        </MUIButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{ width: '33%', borderLeft: '1px solid #ccc', paddingLeft: '10px' }}>
                            <h5 style={{ marginBottom: 10 }}>Subscribed Forms</h5>
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {subscribedForms.map((form) => (
                                    <div key={form.form_id}>
                                        <MUIButton onClick={() => { handleFormSelect(form); setIsEditable(false); }}>
                                            {form.form_name}
                                        </MUIButton>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <hr style={{ borderTop: '1px solid #ccc', margin: '20px 0px' }} />
            <h4 style={{ textDecoration: 'underline' }}>{formName}</h4>
            {formSchema && !isEdit &&
                <div>
                    {isEditable &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -45 }}>
                            <Button
                                style={{
                                    width: '10%',
                                }}
                                onClick={() => setIsEdit(true)}
                                disabled={isEditable ? false : true}
                            >
                                Edit Form
                            </Button>
                        </div>
                    }
                    <div style={{ border: '1px solid #ccc', padding: 10, marginTop: isEditable ? 10 : 20 }}>
                        <Form form={JSON.parse(formSchema)} options={{ readOnly: true }} onSubmit={() => setErrorAlert('This is only a test. For submitting an actual form, please follow the appropriate steps.')} />
                    </div>
                </div>
            }
            {isEdit &&
                <div>
                    <div style={{ border: '1px solid #ccc', padding: 10, marginTop: 20 }}>
                        <FormBuilder form={JSON.parse(formSchema)} options={{ builder: { premium: false } }} onChange={(schema) => handleFormSchemaChange(schema)} />
                    </div>
                    <hr style={{ borderTop: '1px solid #ccc', margin: '20px 0' }} />
                    <Button
                        style={{
                            width: '10%',
                            marginTop: '30px',
                        }}
                        onClick={() => editForm()}
                    >
                        Save Form
                    </Button>
                </div>
            }
            <Snackbar open={successAlert != ''} autoHideDuration={5000} onClose={handleSuccessAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"success"} elevation={6} variant="filled" {...props}>
                    {successAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleSuccessAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorAlert != ''} autoHideDuration={5000} onClose={handleErrorAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleErrorAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>
    )
}