import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Buttton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FileName = (props) => {
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        setFileName(props.fileName);
        console.log(props.fileName);
    }, [props.fileName])

    return (
        <Modal show={props.showFileName} onHide={() => {props.setShowFileName(false)}} centered>
            <Modal.Body>
                <Typography variant="h6">Enter File Name</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="File Name"
                    type="text"
                    fullWidth
                    value={fileName}
                    onChange={(e) => {setFileName(e.target.value)}}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <Buttton variant="contained" onClick={() => {props.setShowFileName(false)}}>Close</Buttton>
                    <Buttton variant="contained" onClick={() => {props.setShowFileName(false); props.setFileName(fileName); props.onSave(fileName)}}>Save</Buttton>
                </Box>
            </Modal.Body>
        </Modal>
    )
}

export default FileName;
