import { useState, useEffect, useCallback, memo } from "react";
import { Typography, Stack, Box } from "@mui/material";
import FollowUpItemRadioGroup from "./FollowUpItemRadioGroup";
import FollowUpItemTextField from "./FollowUpItemTextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const FollowUpItems = (props) => {
    const [followUpItems, setFollowUpItems] = useState([]);
    const [currentMicItem, setCurrentMicItem] = useState(null);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        setFollowUpItems(props.followUpItems);

        return () => {
            setFollowUpItems([]);
        }
    }, [props.followUpItems]);

    useEffect(() => {
        if (!listening) {
            props.setUpdateFollowUpItem({ id: currentMicItem?.id, question: "c", value: followUpItems.find((item) => item.id === currentMicItem?.id)?.c.answer + transcript });
            resetTranscript();
        }
    }, [listening]);

    return (
        <>
        {followUpItems.length > 0 ? followUpItems.map((item, index) => {
            return (
                <Stack key={index} spacing={props.spacing} sx={{ width: '100%', marginBottom: props.margin}}>
                    <Box sx={{ width: '100%', display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginBottom: 1}}>
                        <Typography sx={{ marginBottom: "0px !important", marginRight: props.indent }}><Typography sx={{ display: "inline", fontWeight: "bold", marginRight: props.indent }}>{"Question: "}</Typography>{item?.question}</Typography>
                        <Typography sx={{ marginBottom: "0px !important", marginRight: props.indent, whiteSpace: "nowrap" }}>{item?.mt_period}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: "flex", alignItems: "flex-start"}}>
                        <Typography sx={{ marginBottom: "0px !important", marginRight: props.indent }}><Typography sx={{ display: "inline", fontWeight: "bold", marginRight: props.indent }}>{item?.a?.question}</Typography>{item?.a?.answer}</Typography>
                    </Box>
                    <Stack spacing={props.indent} direction="row" style={{ alignItems: "center" }} >
                        <Typography sx={{ fontWeight: "bold" }}>{item.b?.question}</Typography>
                        <FollowUpItemRadioGroup section={props.section} setSectionError={props.setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No"}]} value={item.b?.answer} onChange={props.setUpdateFollowUpItem} item={item} read={props.read}/>
                    </Stack>
                    <Stack>
                        <Typography sx={{ color: item.b?.answer !== "no" ? "gray" : "black" }}>{item.c?.question}</Typography>
                        <FollowUpItemTextField 
                            section={props.section} 
                            setSectionError={props.setSectionError} 
                            validate={props.validate} 
                            variant={"standard"} 
                            minRows={2} 
                            maxRows={30} 
                            InputProps={{ 
                                readOnly: props.read, 
                                maxLength: 10000,
                                endAdornment: item.b?.answer === "no" ? <InputAdornment position="end">
                                    <IconButton
                                        aria-label="speak"
                                        onClick={() => {
                                            if (!listening) {
                                                setCurrentMicItem(item);
                                                SpeechRecognition.startListening();
                                            } else {
                                                SpeechRecognition.stopListening();
                                            }
                                        }}
                                        sx={{ alignSelf: "end" }}
                                        size="small"
                                    >
                                        <MicIcon sx={{ color: listening && currentMicItem?.id === item.id ? "#3fa7d6" : "grey" }} fontSize="small" />
                                    </IconButton>
                                </InputAdornment> : null
                            }} 
                            value={currentMicItem?.id === item.id ? item.c?.answer + transcript : item.c?.answer}
                            onChange={props.setUpdateFollowUpItem} 
                            item={item} 
                            disabled={item.b?.answer !== "no"} 
                            read={props.read}
                        />
                    </Stack>
                </Stack>    
            )
        }): <Typography sx={{ fontStyle: "italic" }}>{"No follow up items"}</Typography>}
        </>
    );
}

export default memo(FollowUpItems);
