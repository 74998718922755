import { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Box from '@mui/material/Box';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MicIcon from '@mui/icons-material/Mic';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const FollowUpItemModal = (props) => {
    const [readOnly, setReadOnly] = useState(false);
    const [currentMicQuestion, setCurrentMicQuestion] = useState("");

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        let questionId = props.currQuestion?.indexes[props.currQuestion?.indexes.length - 1];
        let action = props.currQuestion?.action;

        if(questionId !== undefined && questionId !== null && questionId !== "" && action !== "none") {
            setReadOnly(true);
        } else {
            setReadOnly(false);
        }
        console.log(props.currQuestion);
    }, [props.currQuestion]);

    useEffect(() => {
        setReadOnly(props.read);
    }, [props.read]);

    useEffect(() => {
        if (!listening) {
            if(currentMicQuestion === "comment") {
                props.onCommentChange({ target: { value: props.comment + transcript } });
            } else if(currentMicQuestion === "follow_up") {
                props.onFollowUpItemChange({ target: { value: props.followUpItem + transcript } });
            }
            resetTranscript();
        }
    }, [listening]);

    const createMenuItems = () => {
        let menuItems = [];
        let questions = props.open ? flattenObj(props.data[props.currQuestion?.indexes[0]]) : [];
        let followUpItems = questions.follow_up?.answer;
        
        questions = Object.keys(questions)
            .filter((questionId) => {
                let exists = false;
                followUpItems.forEach((item) => {
                    if(item.id === questionId && questionId !== props.currQuestion?.indexes[props.currQuestion?.indexes.length - 1]) {
                        exists = true;
                    }
                });
                return !exists;
            })
            .reduce((obj, questionId) => {
                return Object.assign(obj, {
                [questionId]: questions[questionId]
            });
            }, {});
        for(let question in questions) {
            if(question !== "follow_up" && questions[question]?.hasOwnProperty("question") && questions[question]?.question[1] === '.')
                menuItems.push(<MenuItem sx={{ maxWidth: "800px", whiteSpace: 'normal' }} value={Object.keys(questions).find(key => questions[key] === questions[question])} key={question}>{questions[question]?.question}</MenuItem>);
        }
        return menuItems;
    }

    const flattenObj = (ob) => {
        let result = {...ob}
        Object.values(ob).forEach((item) => {
            if ((typeof item) === 'object' && !Array.isArray(item)) {
                if(!item.hasOwnProperty("question")) {
                    result = { ...result, ...item };
                    delete result.item;
                } 
            } 
        });
        return result;
    };

    const findPathByName = (obj, targetName) => {
        const helper = (currentObj, currentPath) => {
          for (let key in currentObj) {
            if (currentObj.hasOwnProperty(key)) {
              const newPath = [...currentPath, key];
              if (key === targetName) {
                return newPath;
              }
              if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
                const result = helper(currentObj[key], newPath);
                if (result) return result;
              }
            }
          }
          return null;
        }
        return helper(obj, []);
      }
      
    return (
        <Modal show={props.open} onHide={props.handleClose} centered>
            <Modal.Body>
                <Stack spacing={2}>
                    <Typography variant="h6">Comment/Follow Up Item</Typography>
                    <FormControl fullWidth size="small">
                        <InputLabel>Question</InputLabel>
                        <Select
                            label="Question"
                            variant="standard"
                            value={props.currQuestion?.indexes[props.currQuestion?.indexes.length - 1] || ""}
                            onChange={(e) => { 
                                let indexes = findPathByName(props.data?.[props.currQuestion?.indexes[0]], e.target.value);
                                indexes = [props.currQuestion?.indexes[0]].concat(indexes);
                                console.log(indexes);
                                
                                props.onQuestionChange(indexes);
                            }}
                            inputProps={{ readOnly: readOnly }}
                        >
                            {createMenuItems()}
                        </Select>
                    </FormControl>
                    <TextField 
                        variant={"outlined"} 
                        multiline minRows={3} 
                        maxRows={100} 
                        error={props.error} 
                        validate={true} 
                        label="Comment" 
                        value={currentMicQuestion === "comment" ? props.comment + transcript : props.comment}
                        onChange={props.onCommentChange} 
                        sx={{ width: "100%" }} 
                        InputProps={{ 
                            readOnly: props.read, 
                            maxLength: 10000,
                            endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="speak"
                                        onClick={() => {
                                            if (!listening) {
                                                setCurrentMicQuestion("comment");
                                                SpeechRecognition.startListening();
                                            } else {
                                                SpeechRecognition.stopListening();
                                            }
                                        }}
                                        sx={{ alignSelf: "end" }}
                                        size="small"
                                    >
                                        <MicIcon sx={{ color: listening && currentMicQuestion === "comment" ? "#3fa7d6" : "grey" }} fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <TextField 
                        variant={"outlined"} 
                        multiline minRows={3} 
                        maxRows={100} 
                        error={props.error} 
                        validate={true} 
                        label="Follow Up Item" 
                        value={currentMicQuestion === "follow_up" ? props.followUpItem + transcript : props.followUpItem}
                        onChange={props.onFollowUpItemChange} 
                        sx={{ width: "100%" }} 
                        inputProps={{ readOnly: props.read, maxLength: 10000 }}
                        InputProps={{ 
                            endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="speak"
                                        onClick={() => {
                                            if (!listening) {
                                                setCurrentMicQuestion("follow_up");
                                                SpeechRecognition.startListening();
                                            } else {
                                                SpeechRecognition.stopListening();
                                            }
                                        }}
                                        sx={{ alignSelf: "end" }}
                                        size="small"
                                    >
                                        <MicIcon sx={{ color: listening && currentMicQuestion === "follow_up" ? "#3fa7d6" : "grey" }} fontSize="small" />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Stack>
            </Modal.Body>
            <Modal.Footer style={{borderTop: "0 none", paddingTop: '0', justifyContent: "space-around"}}>
                <Button color="secondary" variant="contained" onClick={props.handleClose}>Cancel</Button>
                <Button variant="contained" onClick={props.onModalConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FollowUpItemModal;