import { useState, useEffect, useCallback, forwardRef, Fragment } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Add from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box';
import schema from "./schema";
import ControlledInput from "../FormComponents/ControlledInput";
import ControlledRadioGroup from "../FormComponents/ControlledRadioGroup";
import ControlledSelect from "../FormComponents/ControlledSelect";
import ControlledDatePicker from "../FormComponents/ControlledDatePicker";
import ControlledTextField from "../FormComponents/ControlledTextField";
//import ControlledAutocomplete from "../FormComponents/ControlledAutocomplete";
import FollowUpItems from "../FormComponents/FollowUpItems";
import Paper from '@mui/material/Paper';
import header from "../header.png";
import moment from "moment";
import FollowUpItemPopup from "../FormComponents/FollowUpItemPopup";
import UnknownDatePicker from "../FormComponents/UnknownDatePicker";
import FollowUpItemModal from "../FormComponents/FollowUpItemModal";
import NotesPopup from "../FormComponents/NotesPopup";
import InputAdornment from '@mui/material/InputAdornment';
import MicIcon from '@mui/icons-material/Mic';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const MonthlyTool =  forwardRef((props, ref) => {
    const [data, setData] = useState(schema);
    const [indent, setIndent] = useState(2);
    const [spacing, setSpacing] = useState(0);
    const [margin, setMargin] = useState(2);

    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(false);

    const [expanded, setExpanded] = useState(new Array(11).fill(false));
    const [sectionError, setSectionError] = useState(Array.from({length: 11},()=> new Array()));
    const [sectionValid, setSectionValid] = useState(new Array(11).fill(true));

    const [updateQuestion, setUpdateQuestion] = useState(null);
    const [updateFollowUpItem, setUpdateFollowUpItem] = useState(null);

    const errorColor = "#ff2d00";
    const successColor = "#3fa7d6";

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const handleExpand = (index) => (event, isExpanded) => {
        let newExpanded = [...expanded];
        newExpanded[index] = isExpanded;
        setExpanded(newExpanded);
        if(index === 5 && isExpanded) {
            if(data.section_6?.a?.answer === "no" && data.section_6?.follow_up.answer.find((item) => item.id === "a") === undefined) {
                updateData(["section_6", "a"], "no" )
                handleOpen(["section_6", "a"]);
                return
            }
            if(data.section_6?.b?.answer === "yes" && data.section_6?.follow_up.answer.find((item) => item.id === "b") === undefined) {
                updateData(["section_9", "b"], "yes" )
                handleOpen(["section_6", "b"]);
            }
        }
    };

    const handleOpen = (currQuestion) => {
        if(props.read) {
            return;
        }
        let item = data[currQuestion[0]].follow_up.answer.find((item) => item.id === currQuestion[currQuestion.length - 1]);
        if(item) {
            setComment(item.comment);
            setFollowUpItem(item.follow_up_item);
            setEdit(true);
        } else {
            setComment("");
            setFollowUpItem("");
        }
        setOpen(true);
    };

    const handleClose = () => {
        if((updateQuestion?.indexes[0] === "section_6" && updateQuestion?.indexes[1] === "a" && updateQuestion?.value === "no") 
        || (updateQuestion?.indexes[0] === "section_6" && updateQuestion?.indexes[1] === "b" && updateQuestion?.value === "yes")) {
            setError(true);
            return;
        }
        setOpen(false);
        setError(false);
        if(edit) {
            setEdit(false);
            return;
        }
        updateData(updateQuestion.indexes, "");
    };

    const [comment, setComment] = useState("");
    const [followUpItem, setFollowUpItem] = useState("");    

    const onModalConfirm = () => {
        if(followUpItem.trim() === "" && comment.trim() === "" && updateQuestion.action !== "none") {
            setError(true);
            return;
        }
        
        let item = {
            comment: comment,
            id: updateQuestion.indexes[updateQuestion.indexes.length - 1],
            follow_up_item: followUpItem,
            question: updateQuestion.indexes.reduce((acc, key) => acc && acc[key], data).question,
            not_required: updateQuestion.action === "none" ? true : false
        };
        setError(false);
        updateData([updateQuestion.indexes[0], "follow_up"], item, "replace");
        setEdit(false);
        setOpen(false);
    }

    useEffect(() => {
        if(error && (comment.trim() !== "" || followUpItem.trim() !== "")) {
            setError(false);
        }
    }, [comment, followUpItem]);

    const updateData = (indexes, newValue = '', action = null) => {
        if(indexes.length === 0)
            return;

        let indexArray = [...indexes];

        if(!Array.isArray(indexes[0])) {
            indexArray = [indexes];
        }
            
        indexArray.forEach((indexes, i) => {
            let current = data;
            let value = newValue;

            const findIndex = (indexes) => {
                for (let i = 0; i < indexes.length - 1; i++) {
                    current = current[indexes[i]];
                }
            }

            if(Array.isArray(newValue)) {
                value = newValue[i];
            }
            switch(action) {
                case "filter":
                    findIndex([indexes[0], "follow_up"]);
                    current["follow_up"].answer = current["follow_up"].answer.filter(i => i.id !== indexes[1]);
                    updateData(indexes, value);
                    break;
                case "replace":
                    findIndex(indexes);
                    current[indexes[indexes.length - 1]].answer = current[indexes[indexes.length - 1]].answer.filter((item) => item.id !== value.id).concat(newValue).sort((a, b) => a.id.localeCompare(b.id))
                    break;
                case "none":
                    break;
                default:
                    findIndex(indexes);
                    if(indexes[indexes.length - 1])
                        current[indexes[indexes.length - 1]].answer = value;
                    break;
            }
        });
        setUpdateQuestion({ indexes: indexes, value: newValue, action: action });
        props.onChange({...data});
    }
        

    const onFollowUpQuestionChange = (e, currQuestion, required, notRequired) => {
        if(e === required) {
            updateData(currQuestion, e)
            handleOpen(currQuestion);
        } else if (e === notRequired) {
            updateData(currQuestion, e, "filter")
        } else {
            updateData(currQuestion, e)
        }
    }

    const micButton = (question, disabled=false) => {
        if(props.read || disabled || props.ref?.current) {
            return;
        } 

        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={() => {
                        if(listening) {
                            SpeechRecognition.stopListening();
                        } else {
                            setUpdateQuestion({ indexes: question, value: data[question[0]][question[1]].answer, action: "" });
                            SpeechRecognition.startListening();
                        }
                    }}
                    sx={{ alignSelf: "end" }}
                    size="small"
                >
                    <MicIcon sx={{ color: listening && (updateQuestion?.indexes[0] === question[0] && updateQuestion?.indexes[1] === question[1]) ? "#3fa7d6" : "grey"  }} fontSize="small" />
                </IconButton>
            </InputAdornment>
        )
    }
                            

    const displayFollowUpItem = (section) => {
        return (
            <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "bold" }}>Comments</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Typography sx={{ fontWeight: "bold", color: "darkOrange", fontStyle: "italic" }}>*Explain each answer with an asterisk. Include follow up items as applicable.</Typography>
                        <IconButton size="small" onClick={() => {setUpdateQuestion({ indexes: [section, ""], value: "", action: "" });handleOpen([section, ""])}}>
                            <Add fontSize="small"/>
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                    {
                        data[section].follow_up.answer.map((item) => {
                            if (item.comment !== "") {
                                let indexes = [section].concat(findPathByName(data[section], item.id));
                                return (
                                    <Typography sx={{ "&:hover": { background: "lightgray", cursor: "pointer" }}} onClick={() => {setUpdateQuestion({ indexes: indexes, value: indexes?.reduce((acc, key) => acc && acc[key], data).answer, action: "" });handleOpen(indexes)}} key={item.id}>{item.question?.substring(0, 2) + " " + item.comment}</Typography>
                                )
                            }
                        })
                    }
                </Box>
                <Divider/>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontWeight: "bold" }}>Follow Up Items</Typography>
                    <Box sx={{ display: "flex" }}>
                        <IconButton size="small" onClick={() => {setUpdateQuestion({ indexes: [section, ""], value: "", action: "" });handleOpen([section, ""])}}>
                            <Add fontSize="small"/>
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                    {
                        data[section].follow_up.answer.map((item) => {
                           if(item.follow_up_item !== "") {
                                let indexes = [section].concat(findPathByName(data[section], item.id));
                                return (
                                    <Typography sx={{ "&:hover": { background: "lightgray", cursor: "pointer" }}} onClick={() => {setUpdateQuestion({ indexes: indexes, value: indexes?.reduce((acc, key) => acc && acc[key], data).answer, action: "" });handleOpen(indexes)}} key={item.id}>{item.question?.substring(0, 2) + " " + item.follow_up_item}</Typography>
                                )
                           }
                        })
                    }
                </Box>
            </>
        )
    }

    const findPathByName = (obj, targetName) => {
        const helper = (currentObj, currentPath) => {
          for (let key in currentObj) {
            if (currentObj.hasOwnProperty(key)) {
              const newPath = [...currentPath, key];
              if (key === targetName) {
                return newPath;
              }
              if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
                const result = helper(currentObj[key], newPath);
                if (result) return result;
              }
            }
          }
          return null;
        }
        return helper(obj, []);
      }

    const updateText = (item) => {
        let section = data.section_2?.map(i => {
            if (i.id === item.id) {
                let newItem = i;
                newItem[item.question]["answer"] = item.value;
                return newItem;
            }
            return i;
        });
        props.onChange({...data, section_2: section});
    }

    useEffect(() => {
        if (!listening && updateQuestion && !open) {
            updateData(updateQuestion.indexes, updateQuestion.value + transcript, updateQuestion.action);
            resetTranscript();
        }
    }, [listening]);

    useEffect(() => {
        if(updateFollowUpItem) {
            updateText(updateFollowUpItem);
        }
    }, [updateFollowUpItem]);

    useEffect(() => {
        if((Object.keys(props.data).length === 0 || props.data === null)) {
            setData(schema);
        } else {
            setData(props.data);
        }        
    }, [props.data]);
    
    useEffect(() => {
        if(props.expand === true) {
            setExpanded(new Array(16).fill(true));
        }
    }, [props.expand]);

    useEffect(() => {
        if(open === false) {
            if(updateQuestion?.indexes[0] === "section_6" && updateQuestion?.value.id === "a") {
                    let newSectionValid = [...sectionValid];
                    newSectionValid[5] = true
                    setSectionValid(newSectionValid);
            } 
        }
    }, [open]);

    useEffect(() => {
        isSectionComplete()
    }, [sectionError]);

    const isSectionComplete = () => {
        let newSectionValid = [...sectionValid]

        sectionError.forEach((item, index) => {
            if(item.length > 0) {
                newSectionValid[index] = false;
            } else {
                newSectionValid[index] = true;
            }
            if(index === 5 && (data.section_6?.a?.answer === "no" == (data.section_6?.follow_up.answer.find((item) => item.id === "a") !== undefined))
            && (data.section_6?.b?.answer === "yes" == (data.section_6?.follow_up.answer.find((item) => item.id === "b") !== undefined))) {
                newSectionValid[index] = true;
            } else if(index === 5){
                newSectionValid[index] = false;
            }
            if(index === 7) {
                newSectionValid[index] = true;
            }
        });

        setSectionValid(newSectionValid);
        if(props.setValidSections !== undefined)
            props.setValidSections(newSectionValid)
    }
    
    const sectionColor = (index) => {
        if(props.validate) {
            // allow section 8 color act as if question was mandatory
            if(index === 7 && !props.read){
                if(data.section_8?.a?.answer) {
                    return successColor;
                } else {
                    return "black";
                }
            }
            if(sectionValid[index] === false) {
                return errorColor;
            } else {
                return successColor;
            }
        } else {
            // allow section 8 color act as if question was mandatory
            if(index === 7 && !props.read){
                if(data.section_8?.a?.answer) {
                    return successColor;
                } else {
                    return "black";
                }
            }
            if(sectionValid[index] === false || props.read) {
                return "black";
            } else {
                return successColor;
            }
        }
    }

    return (
        <Box ref={ref}>
            <FollowUpItemModal 
                open={open} 
                handleClose={handleClose} 
                onModalConfirm={onModalConfirm} 
                onCommentChange={useCallback((e) => setComment(e.target.value), [])}
                onQuestionChange={(indexes) => setUpdateQuestion({ indexes: [...indexes], value: "", action: "" })}
                onFollowUpItemChange={useCallback((e) => setFollowUpItem(e.target.value), [])} 
                read={props.read} data={data} 
                comment={comment} 
                followUpItem={followUpItem} 
                currQuestion={updateQuestion} 
                error={error} 
            />
            <FollowUpItemPopup sx={{ position: "fixed", top: "1rem", left: "1rem" }} indent={indent} spacing={spacing} margin={margin} section={1} setSectionError={setSectionError} validate={props.validate} comments={props.comments || []} setUpdateFollowUpItem={setUpdateFollowUpItem} read={ref || props.read}/>
            { (props?.resubmit || props?.review) &&
                <NotesPopup sx={{ position: "fixed", top: "1rem", right: "1rem" }} note={props.note} setNote={props.setNote} resubmit={props.resubmit} read={props.read}/>
            }            
            <Box sx={{ margin: "1rem" }}>
                <Box sx={{ display: "flex" }}>
                    <Paper variant="outlined">
                        <img src={header} width={71} alt="header"/>
                    </Paper>
                    <Box sx={{ marginLeft: "1rem" }}>
                        <Typography sx={{ fontWeight: "bold", textAlign: "left", color: "orange", fontSize: 15 }}>New Jersey Department of Human Services</Typography>
                        <Typography sx={{ fontWeight: "bold", textAlign: "left", color: "navy", textDecoration: "underline", fontSize: 16 }}>Division of Developmental Disabilities</Typography>
                    </Box>
                </Box>
                <Typography sx={{ fontWeight: "bold", textAlign: "center", fontSize: 18  }}>Support Coordinator Monitoring Tool - <Typography sx={{ display: "inline", fontWeight: "bold", textAlign: "center", color: "orange", textDecoration: "udnerline", fontSize: 18  }}>Monthly</Typography></Typography>
                <Typography sx={{ textAlign: "center", fontSize: 16  }}>Used in months when a Quarterly Monitoring Tool is not due, to document monitoring contact with Individuals and caregivers.</Typography>
            </Box>          
            <Accordion expanded={expanded[0]} onChange={handleExpand(0)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(0) }}>Section 1: Identifying Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={spacing}>
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center"}}>
                            <Box sx={{ flex: 3, display: "flex", alignItems: "center" , paddingRight: margin}}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: "bold" }}>{data.section_1?.a?.question}</Typography>
                                <ControlledInput read={props.read} disabled value={data.section_1?.a?.answer} inputProps={{ maxLength: 10000, readOnly: true }}  sx={{ flexGrow: 1 }}/>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ flex: 4, display: "flex", alignItems: "center"}}>
                                <Typography sx={{ marginBottom: "0px !important", marginLeft: indent, marginRight: indent, fontWeight: "bold" }}>{data.section_1?.e?.question}</Typography>
                                <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10000, readOnly: true }} value={data.section_1?.e?.answer} onChange={useCallback((e) => updateData(["section_1", "e"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center" }}>
                            <Box sx={{ flex: 3, display: "flex", alignItems: "center" , paddingRight: margin}}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: "bold" }}>{data.section_1?.b?.question}</Typography>
                                <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10, readOnly: true }} value={data.section_1?.b?.answer} onChange={useCallback((e) => updateData(["section_1", "b"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ flex: 4, display: "flex", alignItems: "center" }}>
                                <Typography sx={{ marginBottom: "0px !important", marginLeft: indent, marginRight: indent }}>{data.section_1?.f?.question}</Typography>
                                <ControlledSelect read={props.read} section={0} setSectionError={setSectionError} dependentValue={props.dependentInfo?.section_1?.f} validate={props.validate} options={data.section_1?.f?.options} inputProps={{ readOnly: props.read }} value={data.section_1?.f?.answer} onChange={useCallback((e) => updateData(["section_1", "f"], e), [data])} sx={{ flexGrow: 1 }}/>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center" }}>
                            <Box sx={{ flex: 3, display: "flex", alignItems: "center", paddingRight: margin}}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: "bold" }}>{data.section_1?.c?.question}</Typography>
                                <Box sx={{ width: '100%', display: "flex", alignItems: "center", flex: "25%" }}>
                                    <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10, readOnly: true }} value={data?.section_1?.c?.c1?.answer} onChange={useCallback((e) => updateData(["section_1", "c", "c1"], e), [data])} helperText={data?.section_1?.c?.c1?.question}/>
                                    <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10, readOnly: true }} value={data?.section_1?.c?.c2?.answer} onChange={useCallback((e) => updateData(["section_1", "c", "c2"], e), [data])} sx={{ flexGrow: 1 }} helperText={data?.section_1?.c?.c2?.question}/>
                                    <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10, readOnly: true }} value={data?.section_1?.c?.c3?.answer} onChange={useCallback((e) => updateData(["section_1", "c", "c3"], e), [data])} sx={{ flexGrow: 1 }} helperText={data?.section_1?.c?.c3?.question}/>
                                </Box>
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ flex: 4, display: "flex", alignItems: "center"}}>
                                <Typography sx={{ color: data.section_1?.f?.answer === "other"?"black":"gray", marginBottom: "0px !important", marginLeft: indent, marginRight: indent }}>{data.section_1?.g?.question}</Typography>
                                <ControlledInput read={props.read} section={0} setSectionError={setSectionError} validate={props.validate} disabled={ data.section_1?.f?.answer !== "other" } inputProps={{ readOnly: props.read, maxLength: 10000 }} value={data.section_1?.g?.answer} onChange={useCallback((e) => updateData(["section_1", "g"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center" }}>
                            <Box sx={{ flex: 3, display: "flex", alignItems: "center", paddingRight: margin}}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: "bold" }}>{data.section_1?.d?.question}</Typography>
                                <ControlledInput read={props.read} disabled inputProps={{ readOnly: props.read, maxLength: 1000, readOnly: true }} value={data.section_1?.d?.answer} variant="standard" onChange={useCallback((e) => updateData(["section_1", "f"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                            <Divider orientation="vertical" flexItem />
                            <Box sx={{ flex: 4, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Typography sx={{ marginBottom: "0px !important", marginLeft: indent, marginRight: indent, fontWeight: "bold" }}>{data.section_1?.h?.question}</Typography>
                                <ControlledRadioGroup read={props.read} disabled sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No"}]} value={data.section_1?.h?.answer}onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_3", "b"], "no", "yes"), [data])} inputProps={{ readOnly: true }}/>
                            </Box>
                        </Box>
                    </Stack>
                    <Stack spacing={spacing}>
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center" }}>
                            <Typography sx={{ marginRight: indent, fontWeight: "bold" }}>{data.section_1?.i?.question}</Typography>
                            <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10000, readOnly: true }} value={data.section_1?.i?.answer} onChange={useCallback((e) => updateData(["section_1", "i"], e), [data])} sx={{ flexGrow: 1 }} />
                        </Box>
                        <Box sx={{ width: '100%', display: "flex", alignItems: "center" }}>
                            <Typography sx={{ marginRight: indent, fontWeight: "bold" }}>{data.section_1?.j?.question}</Typography>
                            <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10000, readOnly: true }} value={data.section_1?.j?.answer} onChange={useCallback((e) => updateData(["section_1", "j"], e), [data])} sx={{ flexGrow: 1 }} />
                        </Box>
                    </Stack>
                    <Stack spacing={spacing}>
                        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <Box sx={{ width: '100%', display: "flex", flex: 2, alignItems: "center", paddingRight: indent }}>
                                <Typography sx={{  marginRight: indent, whiteSpace: "nowrap", fontWeight: props.isSubscibedToTimeManagement === true ? "bold" : "normal" }}>{data.section_1?.k?.question}</Typography>
                                <ControlledDatePicker read={props.read} section={0} setSectionError={setSectionError} validate={props.validate} disabled={props.isSubscibedToTimeManagement === true || props.read} readOnly={props.read || props.isSubscibedToTimeManagement === true} value={data.section_1?.k?.answer} onChange={useCallback((newValue) => updateData(["section_1", "k"], newValue), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                            <Box sx={{ width: '100%', display: "flex", flex: 3, alignItems: "center" }}>
                                <Typography sx={{ marginRight: indent, fontWeight: "bold" }}>{data.section_1?.n?.question}</Typography>
                                <ControlledInput read={props.read} disabled inputProps={{ maxLength: 10, readOnly: true }} value={data.section_1?.n?.answer} onChange={useCallback((e) => updateData(["section_1", "n"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <Box sx={{ width: '100%', display: "flex", flex: 2, alignItems: "center", paddingRight: indent }}>
                                <Typography sx={{  marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.l?.question}</Typography>
                                <ControlledSelect read={props.read} section={0} setSectionError={setSectionError} validate={props.validate} options={data.section_1?.l?.options} inputProps={{ readOnly: props.read }} value={data.section_1?.l?.answer} onChange={useCallback((e) => updateData(["section_1", "l"], e), [data])} sx={{ flexGrow: 1 }}/>
                            </Box>
                            <Box sx={{ color: data.section_1?.l?.answer === "other"?"black":"gray", width: '100%', display: "flex", flex: 3, alignItems: "center" }}>
                                <Typography sx={{ marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.o?.question}</Typography>
                                <ControlledInput read={props.read} question={"o"} section={0} setSectionError={setSectionError} validate={props.validate} disabled={data.section_1?.l?.answer !== "other"} inputProps={{ readOnly: props.read, maxLength: 10000 }} value={data.section_1?.o?.answer} onChange={useCallback((e) => updateData(["section_1", "o"], e), [data])} sx={{ flexGrow: 2 }} />
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <Box sx={{ width: '100%', display: "flex", flex: 2, alignItems: "center", paddingRight: indent }}>
                                <Typography sx={{ marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.m?.question}</Typography>
                                <ControlledSelect read={props.read} section={0} setSectionError={setSectionError} validate={props.validate} options={data.section_1?.m?.options} inputProps={{ readOnly: props.read }} value={data.section_1?.m?.answer} onChange={useCallback((e) => updateData(["section_1", "m"], e), [data])} sx={{ flexGrow: 1 }}/>
                            </Box>
                            <Box sx={{ color: data.section_1?.m?.answer === "phone/video_contact"?"black":"gray", width: '100%', display: "flex", flex: 3, alignItems: "center" }}>
                                <Typography sx={{ marginRight: indent }}>{data.section_1?.p?.question}</Typography>
                                <ControlledInput read={props.read} question={"p"} section={0} setSectionError={setSectionError} dependentValue={props.dependentInfo?.section_1?.p} validate={props.validate} disabled={data?.section_1?.m?.answer !== "phone/video_contact"} inputProps={{ readOnly: props.read, maxLength: 10000, type: "tel" }} value={data?.section_1?.p?.answer} onChange={useCallback((e) => updateData(["section_1", "p"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                        </Box>
                    </Stack>
                    <Stack spacing={spacing}>
                        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <Typography sx={{ marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.q?.question}</Typography>
                            <ControlledInput read={props.read} section={0} question={"q"} setSectionError={setSectionError} validate={props.validate} inputProps={{ readOnly: props.read, maxLength: 10000 }} value={data.section_1?.q?.answer} onChange={useCallback((e) => updateData(["section_1", "q"], e), [data])} sx={{ flexGrow: 1 }} />
                        </Box>
                        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                            <Typography sx={{ marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.r?.question}</Typography>
                            <ControlledSelect read={props.read} section={0} setSectionError={setSectionError} validate={props.validate} options={data.section_1?.r?.options} inputProps={{ readOnly: props.read }} value={data.section_1?.r?.answer} onChange={useCallback((e) => updateData(["section_1", "r"], e), [data])} sx={{ flexGrow: 1 }}/>
                        </Box>
                        <Box sx={{ color: data.section_1?.r?.answer !== "self"? "black":"gray"}}>
                            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.s?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={0} setSectionError={setSectionError} validate={props.validate} disabled={data.section_1?.r?.answer === "self" || props.read} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No"}]} value={data.section_1?.s?.answer} onChange={useCallback((e) => updateData(["section_1", "s"], e), [data])}/>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ marginRight: indent, whiteSpace: "nowrap" }}>{data.section_1?.t?.question}</Typography>
                                <ControlledInput read={props.read} question={"t"} section={0} setSectionError={setSectionError} validate={props.validate} disabled={data.section_1?.r?.answer === "self"} inputProps={{ readOnly: props.read, maxLength: 10000 }} value={data.section_1?.t?.answer} onChange={useCallback((e) => updateData(["section_1", "t"], e), [data])} sx={{ flexGrow: 1 }} />
                            </Box>
                        </Box>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[1]} onChange={handleExpand(1)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(1) }}>Section 2: Follow Up Items from Previous Months’ Contact</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Stack spacing={spacing} divider={<Divider/>}>
                            <FollowUpItems indent={indent} spacing={spacing} margin={margin} section={1} setSectionError={setSectionError} validate={props.validate} followUpItems={data?.section_2} setUpdateFollowUpItem={setUpdateFollowUpItem} read={props.read}/>
                        </Stack>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[2]} onChange={handleExpand(2)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(2) }}>Section 3: Outcomes and Services</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={{ color: 'darkOrange' }}>
                        <b>Review ALL</b> current outcomes and services as they appear in the ISP with the Individual/MT contact. Include services provided by natural supports. 
                        If there are no funded services in the ISP, explain why not.
                    </Typography>
                    <Stack divider={<Divider/>}>
                        <Stack spacing={spacing}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_3?.a?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={2} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No*"}]} value={data.section_3?.a?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_3", "a"], "no", "yes"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_3?.b?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={2} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No*"}]} value={data.section_3?.b?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_3", "b"], "no", "yes"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography>{data.section_3?.c?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={2} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No*"}]} value={data.section_3?.c?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_3", "c"], "no", "yes"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_3?.d?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={2} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_3?.d?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_3", "d"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                        </Stack>
                        {displayFollowUpItem("section_3")}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[3]} onChange={handleExpand(3)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(3) }}>Section 4: Continuity and Stability with Living Arrangement</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Stack spacing={spacing}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_4?.a?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={3} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_4?.a?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_4", "a"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_4?.b?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={3} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_4?.b?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_4", "b"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                        </Stack>
                        {displayFollowUpItem("section_4")}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[4]} onChange={handleExpand(4)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(4) }}>Section 5: Health and Safety</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Stack spacing={spacing}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_5?.a?.question}</Typography>
                                <ControlledRadioGroup read={props.read} question={"a"} section={4} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_5?.a?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_5", "a"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_5?.b?.question}</Typography>
                                <ControlledRadioGroup read={props.read} question={"b"} section={4} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_5?.b?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_5", "b"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography>{data.section_5?.c?.question}</Typography>
                                <ControlledRadioGroup read={props.read} question={"c"} section={4} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_5?.c?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_5", "c"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ color: data.section_5?.c?.answer === "yes" ? "black" : "gray", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_5?.d?.question}</Typography>
                                <ControlledRadioGroup read={props.read} question={"d"} section={4} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_5?.d?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_5", "d"], "yes", "no"), [data])} disabled={data.section_5?.c?.answer !== "yes" || props.read}/>
                            </Box>
                        </Stack>
                        {displayFollowUpItem("section_5")}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[5]} onChange={handleExpand(5)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(5) }}>Section 6: Medicaid Status</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Typography sx={{ fontWeight: "bold", fontStyle: "italic" }}>(SC will find status of a and b below on Individual’s Demographics tile in iRecord)</Typography>
                        <Stack spacing={spacing}>
                            <Box sx={{ color: "gray", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 1, marginRight: indent, fontWeight: "bold" }}>{data.section_6?.a?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={5} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes"}, { value: "no", label: "No*"}]} value={data?.section_6?.a?.answer} disabled={true} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_6", "a"], "no", "yes"), [data])}/>
                            </Box>
                            <Box sx={{ color: "gray", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 1, marginRight: indent, fontWeight: "bold" }}>{data.section_6?.b?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={5} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data?.section_6?.b?.answer} disabled={true} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_6", "b"], "yes", "no"), [data])}/>
                            </Box>
                        </Stack>
                        {displayFollowUpItem("section_6")}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[6]} onChange={handleExpand(6)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(6) }}>Section 7: Areas Requiring Division Assistance</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Stack spacing={spacing}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Typography sx={{ flex: 2, marginRight: margin }}>{data.section_7?.a?.question}</Typography>
                                <ControlledRadioGroup read={props.read} section={6} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_7?.a?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_7", "a"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <Box sx={{ flex: 2, marginRight: margin }}>
                                    <Typography>{data.section_7?.b?.question}</Typography>
                                    <Typography sx={{ color: "darkOrange", fontWeight: "bold" }}>If either a. or b., complete/upload an SOS form, upload in iRecord and notify the <a href="mailto:DDD.SCHelpdesk@dhs.nj.gov">DDD SCHelpdesk</a></Typography>
                                </Box>
                                <ControlledRadioGroup read={props.read} section={6} setSectionError={setSectionError} validate={props.validate} sx={{ marginBottom: "0px !important" }} options={[{ value: "yes", label: "Yes*"}, { value: "no", label: "No"}]} value={data.section_7?.b?.answer} onChange={useCallback((e) => onFollowUpQuestionChange(e, ["section_7", "b"], "yes", "no"), [data])} disabled={props.read}/>
                            </Box>
                        </Stack>
                        {displayFollowUpItem("section_7")}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[7]} onChange={handleExpand(7)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(7) }}>Section 8: Closing Question for the Individual/Caregiver</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Typography>{data.section_8?.a?.question}</Typography>
                        <ControlledTextField  read={props.read} section={7} setSectionError={setSectionError} variant="standard" minRows={3} maxRows={100} InputProps={{ readOnly: props.read, maxLength: 10000, endAdornment: micButton(["section_8", "a"])}} value={updateQuestion?.indexes[0] === "section_8" && updateQuestion?.indexes[1] === "a" ? data.section_8?.a?.answer + transcript : data.section_8?.a?.answer} onChange={useCallback((e) => updateData(["section_8", "a"], e ), [data])}/>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[8]} onChange={handleExpand(8)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(8) }}>Section 9: Contact Summary</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Typography>{data.section_9?.a?.question}</Typography>
                        <ControlledTextField mtNA={props.mtNA} read={props.read} section={8} setSectionError={setSectionError} validate={props.validate} variant="standard" minRows={3} maxRows={100} InputProps={{ readOnly: props.read, maxLength: 10000, endAdornment: micButton(["section_9", "a"]) }}  value={updateQuestion?.indexes[0] === "section_9" && updateQuestion?.indexes[1] === "a" ? data.section_9?.a?.answer + transcript : data.section_9?.a?.answer}  onChange={useCallback((e) => updateData(["section_9", "a"], e ), [data])}/>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[9]} onChange={handleExpand(9)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(9) }}>Section 10: Completed By</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={spacing}>
                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", alignItems: "center" }}>
                            <Box sx={{ width: '100%', display: "flex", alignItems: "center", flex: "50%" }}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: 'bold' }}>{data.section_10?.a?.question}</Typography>
                                <ControlledInput read={props.read} disabled inputProps={{ readOnly: props.read, maxLength: 10000 }} value={data.section_10?.a?.answer} onChange={useCallback((e) => updateData(["section_10", "a"], e ), [data])} sx={{ marginTop: "0px !important", flexGrow: 1, marginRight: margin }}/>
                            </Box>
                            <Box sx={{ width: '100%', display: "flex", alignItems: "center", flex: "50%" }}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: 'bold' }}>{data.section_10?.b?.question}</Typography>
                                <ControlledDatePicker read={props.read} disabled readOnly={props.read} value={data.section_10?.b?.answer} onChange={useCallback((newValue) => updateData(["section_10", "b"], newValue ), [data])} sx={{ flexGrow: 1 }}/>
                            </Box>
                            <Box sx={{ width: '100%', display: "flex", alignItems: "center", flex: "50%" }}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: 'bold' }}>{data.section_10?.c?.question}</Typography>
                                <ControlledInput read={props.read} disabled inputProps={{ readOnly: props.read, maxLength: 10000 }} value={data.section_10?.c?.answer} onChange={useCallback((e) => updateData(["section_10", "c"], e ), [data])} sx={{ marginTop: "0px !important", flexGrow: 1 }}/>
                            </Box>
                        </Box>
                        <Typography>{data.section_10?.d?.question}</Typography>
                        <ControlledTextField mtNA={props.mtNA} mtN read={props.read} variant="standard" minRows={3} maxRows={100} InputProps={{ readOnly: props.read, maxLength: 10000, endAdornment: micButton(["section_10", "d"]) }} value={updateQuestion?.indexes[0] === "section_10" && updateQuestion?.indexes[1] === "d" ? data.section_10?.d?.answer + transcript : data.section_10?.d?.answer} onChange={useCallback((e) => updateData(["section_10", "d"], e ), [data])}/>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded[10]} onChange={handleExpand(10)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontWeight: 'bold', color: sectionColor(10) }}>Section 11: Reviewed By</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack divider={<Divider/>}>
                        <Typography sx={{ fontWeight: 'bold', fontStyle: "italic" }}>SCS review is required for the first 60 days of any new Support Coordinator, when performance issues have been identified and for complicated or difficult situations.</Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%", alignItems: "center" }}>
                            <Box sx={{ width: '100%', display: "flex", alignItems: "center", flex: "50%" }}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent }}>{data.section_11?.a?.question}</Typography>
                                <ControlledInput read={props.read} disabled={!props.read} inputProps={{ readOnly: !props.read, maxLength: 1000 }} value={data.section_11?.a?.answer} onChange={useCallback((e) => updateData(["section_11", "a"], e ), [data])} sx={{ marginTop: "0px !important", flexGrow: 1, marginRight: margin }}/>
                            </Box>
                            <Box sx={{ width: '100%', display: "flex", alignItems: "center", flex: "50%" }}>
                                <Typography sx={{ marginBottom: "0px !important", marginRight: indent, fontWeight: 'bold' }}>{data.section_11?.b?.question}</Typography>
                                <ControlledDatePicker read={props.read} disabled={!props.read} value={data.section_11?.b?.answer} onChange={useCallback((newValue) => updateData(["section_11", "b"], newValue), [data])} sx={{ flexGrow: 1 }}/>
                            </Box>
                        </Box>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Box>
                <Typography sx={{ margin: "1rem", color: "gray" }} variant="subtitle2" >NJ Division of Developmental Disabilities | SC Monitoring Tool - Monthly | {moment(data.section_1?.k?.answer).utc().format("MMMM YYYY")}</Typography>
            </Box>
        </Box>
   );
});

export default MonthlyTool;