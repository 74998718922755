import React, { useState, useEffect } from "react";
import { Box, TablePagination, Stack, Grid, Typography, Tab, Tabs, InputLabel, MenuItem, FormControl, Select, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import makeStyles from "@mui/styles/makeStyles";
import { Button } from "react-bootstrap";
import CustomerNotesReport from "./CustomerNotesReport";
import ReportCaseManagerCard from "./ReportCaseManagerCard";

const useStyles = makeStyles((theme) => ({
  tabss: {
    "& .MuiTab-root": { textTransform: "capitalize" },
    "& .Mui-selected": {
      backgroundColor: "#edebeb7a",
      fontWeight: "bold",
      fontFamily: "Lato",
    },
  },
}));

const typesList = ["Monthly", "Quarterly", "Yearly"];

const CaseManager = (props) => {
  const classes = useStyles();

  const [page, setPage] = useState(props?.caseManagerFilterData?.page);
  const [rowsPerPage, setRowsPerPage] = useState(props?.caseManagerFilterData?.page_size);
  const [tabValue, setTabValue] = useState(1);
  const [filterData, setFilterData] = useState(props?.caseManagerFilterData || {});
  const [caseFilterData, setCaseFilterData] = useState({
    client: {},
    type: "",
    date: null,
  });

  const handleSortBy = (sort_by, sort_order) => {
    props?.onChangePagination({
      page: 0,
      page_size: rowsPerPage,
      sort_by: "cus_name",
      sort_order,
    });
  };

  const handleFilterData = (e, key) => {
    let temp = { ...caseFilterData };
    temp[key] = e.target.value;
    setCaseFilterData({ ...temp });

    if (key === "client") {
      props?.setState({
        ...props?.state,
        caseManagerFilterData: {
          ...props?.state.caseManagerFilterData,
          customer_id: e.target.value ? e.target.value.cus_id : null,
          customer: e.target.value,
        },
      });
    } else if (key === "type") {
      props?.setState({
        ...props?.state,
        caseManagerFilterData: {
          ...props?.state.caseManagerFilterData,
          reportType: e.target.value,
        },
      });
    }
  };

  const searchFilter = (e) => {
    props?.onChangePagination({ ...props?.state?.caseManagerFilterData, page: 0 });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    props?.onChangePagination({
      ...props?.caseManagerFilterData,
      page: newPage,
      page_size: rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props?.onChangePagination({
      ...props?.caseManagerFilterData,
      page: 0,
      page_size: parseInt(event.target.value, 10),
    });
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              fontFamily: "Lato",
              marginBottom: 15,
            }}
          >
            Case Manager Reports
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={9} className="mb-4">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          onChange={handleChange}
          value={tabValue}
          className={classes.tabss}
          variant="scrollable"
        >
          <Tab
            label="Customer Reports"
            value={1}
          />
          <Tab
            label="Employee Reports"
            value={2}
          />
        </Tabs>
      </Grid>

      {tabValue == 2 ? (
        <Grid xs={12}>
          <CustomerNotesReport user={props.user} comp_id={props.comp_id} />
        </Grid>
      ) : null}

      {tabValue == 1 ? (
        <Grid>
          <Grid container className="mb-4">
            <Grid item xs={2} className="mr-2">
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-select-small">Select Client</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props?.state?.caseManagerFilterData?.customer}
                  label="Select clients"
                  onChange={(newValue) => {
                    handleFilterData(newValue, "client");
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {props?.customersList.map((type) => (
                    <MenuItem value={type}>{type.cus_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} className="mr-2">
              <FormControl size="small" fullWidth>
                <InputLabel id="demo-select-small">Select Type</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={props?.state?.caseManagerFilterData?.reportType}
                  label="Select Type"
                  onChange={(newValue) => {
                    handleFilterData(newValue, "type");
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {typesList.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} className="mr-2">
              <FormControl size="small" fullWidth>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{ start: "From Date", end: "To Date" }}
                >
                  {!props?.state?.caseManagerFilterData?.reportType ? (
                    <DateRangePicker
                      value={props?.state?.caseManagerFilterData?.dateRange}
                      onChange={(newValue) => {
                        props?.setState({
                          ...props?.state,
                          caseManagerFilterData: {
                            ...props?.state.caseManagerFilterData,
                            page: 0,
                            dateRange: newValue,
                          },
                        });
                      }}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField size="small" {...startProps} />
                          <Box sx={{ mx: 1 }}> to </Box>
                          <TextField size="small" {...endProps} />
                        </React.Fragment>
                      )}
                    />
                  ) : null}
                  {props?.state?.caseManagerFilterData?.reportType === "Yearly" ? (
                    <DatePicker
                      label="Select year"
                      openTo="year"
                      views={['year']}
                      value={props?.state?.caseManagerFilterData?.dateForType}
                      maxDate={new Date()}
                      onChange={(newValue) => {
                        props?.setState({
                          ...props?.state,
                          caseManagerFilterData: {
                            ...props?.state.caseManagerFilterData,
                            page: 0,
                            dateForType: newValue,
                          },
                        });
                      }}
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                  ) : null}
                  {props?.state?.caseManagerFilterData?.reportType === "Monthly" ? (
                    <DatePicker
                      label="Select month"
                      openTo="month"
                      views={['month']}
                      format="MMMM"
                      maxDate={new Date()}
                      value={props?.state?.caseManagerFilterData?.dateForType}
                      onChange={(newValue) => {
                        props?.setState({
                          ...props?.state,
                          caseManagerFilterData: {
                            ...props?.state.caseManagerFilterData,
                            page: 0,
                            dateForType: newValue,
                          },
                        });
                      }}
                      renderInput={(params) => <TextField size="small" {...params} />}
                    />
                  ) : null}
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={1}>
              <Button
                onClick={(e) => {
                  searchFilter(e);
                }}
                variant="primary"
                size="lg"
                className="font-weight-bold"
                style={{ fontSize: 14, fontFamily: "Lato" }}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>

          {props?.data?.length !== 0 ? <Grid container className="mb-2">
            <Grid
              item
              xs={10}
              container
              direction="row"
              justifyContent="flex-end"
              className="mb-2"
              alignItems="center"
            >
              <div className="dropdown">
                <button
                  style={{ outline: "none" }}
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    <i className="fas fa-sort-amount-up"></i>
                  </span>
                  SORT BY
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button
                    onClick={() => {
                      handleSortBy(
                        "cus_name",
                        filterData.sort_order === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="dropdown-item text-capitalize"
                    type="button"
                  >
                    Name ({filterData.sort_order === "asc" ? "desc" : "asc"})
                  </button>
                </div>
              </div>
            </Grid>
          </Grid> : null}

          {props?.data?.length !== 0 ? (
            <Grid
              container
              className="mt-2"
              style={{ bottom: "10px", position: "relative" }}
            >
              <Grid item xs={10}>
                {props?.data?.map((e) => (
                  <ReportCaseManagerCard
                    data={e}
                    openReport={props?.openReport}
                    markFavourite={props?.markFavourite}
                  />
                ))}
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={10}
              >
                <Stack direction="row" spacing={2}>
                  <TablePagination
                    component="div"
                    count={props?.totalCount || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={10}>
              <Grid
                container
                justifyContent={"center"}
                style={{ marginTop: 100 }}
              >
                <div>
                  <img
                    src="./EVV_reports.png"
                    alt="EVV_reports"
                    style={{ width: "10rem", height: "10rem", margin: 10 }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <h5
                    style={{
                      fontFamily: "Lato",
                      marginTop: 48,
                      marginBottom: 30,
                    }}
                    className="text-center"
                  >
                    No Reports so far. Try with filters to get any reports{" "}
                  </h5>
                  <p
                    style={{ fontSize: 14, width: "100%", fontFamily: "Lato" }}
                    className="text-muted text-center text-wrap"
                  >
                    You’ll see an overview all reports here.
                  </p>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CaseManager;
