import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { APIURL } from '../../config'
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Typography from '@mui/material/Typography';
import HandyCard from '../HandyCard/HandyCard';
import ViewMTModal from '../SupportCoordinator/Modals/ViewMTModal';


const ReviewMMT = (props) => {

    const [status, setStatus] = useState('');
    const [note, setNote] = useState('');
    const [data, setData] = useState({});
    const [type, setType] = useState('');
    const [mmId , setMMId] = useState('');

    const [artifactsDisplay, setArtifactsDisplay] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showDoc, setShowDoc] = useState(false);
    const handleDocClose = () => setShowDoc(false);
    const handleDocShow = () => setShowDoc(true);

    useEffect(() => {
        if(props.mm) {
            setData(JSON.parse(props.mm.data));
            setType(props.mm.mt_type);
            setNote(props.mm.comment ? props.mm.comment : '');
            setMMId(props.mm.monthly_monitoring_id);
        } else {
            setNote('');
        }
        setStatus(props.status);
        setArtifactsDisplay(props.artifacts ? props.artifacts.sort((a, b) => new Date(a.submitted) - new Date(b.submitted)) : []);
        console.log(props);
    }, [props])

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleTextChange = (event) => {
        setNote(event.target.value);
    };

    const onSubmit = async () => {
        if(status !== '') {
/*             let data = JSON.parse(props.mm.data);
            if(type === 'Monthly') {
                data.section_11.a.answer = props.user.emp_name;
                data.section_11.b.answer = moment().utc().format('YYYY-MM-DD');
            } else if(type === 'Quarterly') {
                data.section_16.a.answer = props.user.emp_name;
                data.section_16.b.answer = moment().utc().format('YYYY-MM-DD');
            }  */
            let task_res = await axios.put(APIURL+"/task/status", {empId: props.user.emp_id, empName: props.user.emp_name, companyId: props.user.comp_id, tas_id: props.mm.task_id, tas_mm_status: status });
            let mmt_res = await axios.put(APIURL+"/monthlymonitoring", { 
                task_id: props.mm.task_id, 
                emp_id: props.mm.employee_id, 
                data: JSON.stringify(data), 
                approved: status==='Approved'?1:0, 
                submitted: true, 
                comment: note, 
                submitted_on: props.mm.submitted_on, 
                reviewed_on: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
                mt_type: props.mm.mt_type,
                is_annual: props.mm.is_annual ? props.mm.is_annual : 0,
                loc16: props.mm.loc16 ? props.mm.loc16 : 0,
            });
            if(task_res.status === 200 && mmt_res.status === 200) {
                props.updateTaskStatus(props.cus_id, status, props.task.tas_id, props.month);
                props.handleClose();
            }
        }
    }
    
    return (
        <Box>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Body>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>Review Monitoring Tool</Modal.Title>
                    </Modal.Header>
                    <Box sx={{margin: 2}}>
                        <div className='mb-4'>
                                <span>MT Form: </span><Link href="#" onClick={handleShow} underline="always">{(props.emp_name + " " + props.cus_name + " " + type + " " + "Monitoring Tool").replace(' ', '_')}</Link>
                        </div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Status"
                                onChange={handleSelectChange}
                                className='mb-4'
                            >
                                <MenuItem value={'Approved'}>Approved</MenuItem>
                                <MenuItem value={'Rejected'}>Rejected</MenuItem>
                            </Select>
                            <TextField
                                id="outlined-multiline-static"
                                label="Notes"
                                multiline
                                rows={10}
                                value={note}
                                inputProps={{ maxLength: 10000 }}
                                onChange={handleTextChange}
                            />
                        </FormControl>
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <Button onClick={handleDocShow}>Documents</Button>
                        <Box>
                            <Button style={{ marginRight: 6 }} onClick={props.handleClose}>Cancel</Button>
                            <Button onClick={onSubmit}>Submit</Button>
                        </Box>
                    </Box>
                </Modal.Footer>
            </Modal>
            <ViewMTModal 
                user={props.user}
                show={show} 
                handleClose={handleClose} 
                data={data}
                mm_id={mmId}
                type={type} 
                cus_name={props.cus_name}
                checkoutEmpDate={props.task?.checkoutEmpDate}
                setNote={handleTextChange}
                note={note}
                review={true}
            />
            <Modal show={showDoc} onHide={handleDocClose} dialogClassName="mm-modal-width">
                <Modal.Body>
                    <Modal.Header closeButton={false}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>Documents</Typography>                    
                    </Modal.Header>
                    <Box sx={{ display: "flex", justifyContent: "around", overflow: "scroll", width: "100%"}}>
                        {artifactsDisplay.length > 0 && artifactsDisplay.map((art) => {
                            let artifact = art;
                            return (
                                <Box sx={{ minWidth: "432px" }}>                                
                                    <HandyCard
                                        key={artifact.id}
                                        id={artifact.id}
                                        artifact={true}
                                        fileName={artifact.file_name}
                                        fileType={artifact.url.split('.').pop()}
                                        taskName={artifact.tas_title}
                                        empName={artifact.emp_name}
                                        url={artifact.url}
                                        date={artifact.submitted}
                                        pname={artifact.pro_title}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDocClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Box>
        
    )
}

export default ReviewMMT