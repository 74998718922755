import React, { useState, useEffect, useCallback } from 'react';
import { 
    useGridApiRef,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
 } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import moment from 'moment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button } from "@mui/material";
import ReviewMMT from './ReviewMMT';
import ToggleButton from './ToggleButton';
import BulkDownloadButton from '../../container/SupportCoordinator/BulkDownloadButton'
import './SupportCoordinatorManagement.css';
import {APIURL} from './../../config'
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from "react-router-dom";
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import SaveViewButton from '../../components/DataGrid/SaveViewButton';
import { Call, Home, Clear, Timelapse, Person } from '@mui/icons-material';
import { ReactComponent as FaceToFace } from './../SupportCoordinator/facetoface.svg';
import SvgIcon from '@mui/material/SvgIcon';
import JSZip from 'jszip';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


function SupprotCoordinatorManagement(props) {
    const [showReview, setShowReview] = useState(false);
    const [reviewData, setReviewData] = useState({});

    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);

    const [month, setMonth] = useState('month3');

    const [rowHeight, setRowHeight] = useState(40);

    const [employees, setEmployees] = useState([]);
    const [projects, setProjects] = useState([]);

    const [isGrouped, setIsGrouped] = useState(true);
    const [gridId, setGridId] = useState(52);
    const apiRef = useGridApiRef();

    const [monthlyTemplate, setMonthlyTemplate] = useState(null);
    const [quarterlyTemplate, setQuarterlyTemplate] = useState(null);
    const [isBulkDownloadLoading, setIsBulkDownloadLoading] = useState(false);

    const handleShowReview = () => {setShowReview(true)};
    const handleCloseReview = () => setShowReview(false);
    const [loggedToSegment, setLoggedToSegment] = useState(false);

    const templateString = props.user.comp_settings.find(setting => setting.feature_id === 53)?.feature_value;
    const isSubscibedToTimeManagement = props.user.subs.find((setting) => setting.feature_id === 12) ? true : false;

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page + " : SC Management",{
            title:page + " : SC Management",
            path:page + " : SC Management",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    const setAlert = (isUpdated) => {
        isUpdated ? setUpdated(true) : setError(false);
    }

    function FaceToFaceIcon(props) {
        return (
          <SvgIcon {...props} viewBox="0 0 256 256" component={FaceToFace}/>
        );
    }

    const reviewComparater = (v1, v2) => {
        let v1Status = moment().subtract(3, 'years');
        let v2Status = moment().subtract(3, 'years');

        if(!v1 || !v2) 
            return 0;
            
        v1[month].map(tas => {
            if(tas.tas_mm_id) {
                if(tas.tas_mm_status === 'Waiting For Approval') {
                    v1Status = moment(tas.submitted_on);
                } else if (tas.tas_mm_status === 'Approved') {
                    v1Status = moment(tas.submitted_on).subtract(2, 'years');
                } else if (tas.tas_mm_status === 'Rejected') {
                    v1Status = moment(tas.submitted_on).subtract(1, 'years');
                }
                return;
            }
        })
        v2[month].map(tas => {
            if(tas.tas_mm_id) {
                if(tas.tas_mm_status === 'Waiting For Approval') {
                    v2Status = moment(tas.submitted_on);
                } else if (tas.tas_mm_status === 'Approved') {
                    v2Status = moment(tas.submitted_on).subtract(2, 'years');
                } else if (tas.tas_mm_status === 'Rejected') {
                    v2Status = moment(tas.submitted_on).subtract(1, 'years');
                }
                return;
            }
        })

        return v1Status - v2Status;
    }

    useEffect(async () => {
        let employees = await axios.get(APIURL+'/dropdowns/dropdownType/employeeslist', { params: {comp_id: props.comp_id }});
        let projects = await axios.get(APIURL+'/dropdowns/dropdownType/projectslist', { params: {comp_id: props.comp_id }})
        logToSegment();
        setEmployees(employees.data || []);
        setProjects(projects.data || []);
    }, [])

    useEffect(async () => {
        let monthlyTemplate = await axios.get(APIURL+"/monthlymonitoring/template/" + "monthly", { responseType: 'arraybuffer' });
        let quarterlyTemplate = await axios.get(APIURL+"/monthlymonitoring/template/" + "quarterly", { responseType: 'arraybuffer' });
        monthlyTemplate = monthlyTemplate.data;
        quarterlyTemplate = quarterlyTemplate.data;
        setMonthlyTemplate(monthlyTemplate);
        setQuarterlyTemplate(quarterlyTemplate);
    }, []);

    let columns = [
        {
            field: 'customers',
            headerName: 'Customers',
            width: 200,
            hide: false,
            type: "string",
            renderCell: (params) => {
                return (
                    <NavLink
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center"
                            }}
                            to={{
                                pathname:'/customers',
                                customersProps:{
                                    cus_id: params.row.cus_id,
                                    edit: false
                                }
                            }} 
                        >{params.row.cus_name}</NavLink>
                )
            }
        },
        //{ field: 'cus_id', headerName: 'ID#', width: 100 },
        {
            field: 'mt_period',
            headerName: 'MT Period',
            width: 200,
            hide: false,
            type: "date",
            renderCell: (params) => {
                if(!params.value) {
                    return;
                }
                let numOfMonths = month === 'month3' ? 0 : month === 'month2' ? 1 : 2;
                return moment(params.value).subtract(numOfMonths, "month").startOf("month").format('MM/DD/YYYY') + ' - ' + moment(params.value).subtract(numOfMonths, "month").endOf("month").format('MM/DD/YYYY');
            }
        },
        {
            field: 'review_mt',
            headerName: 'Review MT',
            width: 200,
            hide: false,
            sortComparator: reviewComparater,
            renderHeader: (params) => {
                const handleChange = (event) => {
                    setMonth(event.target.value);
                }
                return (
                    <FormControl fullWidth>
                        <Select
                            id="review-mmt-select"
                            value={month}
                            label="Review MMT"
                            onChange={handleChange}
                        >
                            <MenuItem value={'month3'}>{'MT(' + moment().subtract(0, "month").startOf("month").format('MMM') + ')'}</MenuItem>
                            <MenuItem value={'month2'}>{'MT(' + moment().subtract(1, "month").startOf("month").format('MMM') + ')'}</MenuItem>
                            <MenuItem value={'month1'}>{'MT(' + moment().subtract(2, "month").startOf("month").format('MMM') + ')'}</MenuItem>
                        </Select>
                    </FormControl>
                )

            },
            renderCell: (params) => {
                let task;
                let status="Not Submitted";
                let disable=true;
                let className;

                if(!params.value) {
                    return;
                }

                if(params.value.length === 0) {
                    return <Button disabled={true} size="small" variant="text">{status}</Button>;
                }

                const onClick = async (status) => {
                    let mm = await axios.get(APIURL+"/monthlymonitoring/"+ task.tas_id);
                    let artifacts = await axios.get(APIURL+"/artifacts", { params: { tas_id: task.tas_id } });

                    artifacts = artifacts.data.map((art)=> {
                        const employee = employees.find(emp => art.employee_id === emp.id);
                        const project = projects.find(proj => art.project_id === proj.pid);
            
                        return { ...art, tas_title: task.tas_title, emp_name: employee.employee_name, pro_title: project.pname }           
                    })

                    let data = {
                        mm: mm.data[0],
                        emp_name: params.row.hierarchy[0],
                        cus_name: params.row.cus_name,
                        cus_id: params.row.cus_id,
                        status: status==="Approved"?"Approved":status==="Rejected"?"Rejected":"",
                        artifacts: artifacts,
                        task: task,
                        month: month

                    }
                    setReviewData(data);
                    handleShowReview();
                }

                params.value[month].map(tas => {
                    if(tas.tas_mm_id !== null && tas.tas_mm_id !== 0 && tas.submitted_on) {
                        task = tas;
                        if(tas.tas_mm_status === 'Waiting For Approval') {
                            status = "Review";
                            disable = false;
                        } else if (tas.tas_mm_status === 'Approved') {
                            status = "Approved";
                            disable = false;
                            className = "approved";
                        } else if (tas.tas_mm_status === 'Rejected') {
                            status = "Rejected";
                            disable = false;
                            className = "rejected";
                        }
                        return;
                    }
                })

                return (
                    <Button disabled={disable} className={className} onClick={()=> {if(status!=="Not Submitted") onClick(status)}} size="small" variant="text">{status}</Button>
                )
            }
        },
        {
            field: 'contact_details',
            headerName: 'Contact Details',
            width: 450,
            hide: false,
            renderCell: (params) => {

                if(!params.row.review_mt) {
                    return <span></span>;
                }

                let phone, address;

                const getIcon = (task) => {
                    let taskType = task.tas_type.toLowerCase();
                    taskType = taskType.includes('visit') && taskType !== 'home visit' ? 'visit' : taskType;
                    switch(taskType) {
                        case 'phone call':
                            phone = task.con_phone?.split(",")[0];
                            return <Call style={{color : "#3FA7D6", marginRight : 10}} />
                        case 'home visit':
                            address = task.address;
                            return <Home style={{color : "#3FA7D6", marginRight : 10}} />
                        case 'visit':
                            address = task.address;
                            return <FaceToFaceIcon style={{ height: "22px", color : "#3FA7D6", marginRight: 10 }} />
                        default:
                            return <Clear style={{color : "red", marginRight : 10, visibility: "hidden"}} />
                    }
                }

                const getDuration = (start, end) => {
                    console.log(start, end);
                    let duration = moment.duration(moment(end).diff(moment(start)));
                    let days = duration.days();
                    let hours = duration.hours();
                    let minutes = duration.minutes();

                    let durationStr = "";

                    days > 0 && (durationStr += days + "d ");
                    hours > 0 && (durationStr += hours + "h ");
                    minutes > 0 && (durationStr += minutes + "m");

                    return durationStr;
                }

                let task;

                params.row.review_mt[month].map(tas => {
                    if(tas.tas_mm_id) {
                        if(tas.tas_mm_status === 'Submitted' || tas.tas_mm_status === 'Waiting For Approval' ||
                            tas.tas_mm_status === 'Approved' || tas.tas_mm_status === 'Rejected') {
                                task = tas;       
                        }
                    }
                })

                if(!task) {
                    return <span></span>;
                }

                return (
                    <Box sx={{ width: "100%", height: rowHeight }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {getIcon(task)}
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                width: "100%",
                                height: "100%",
                            }}>
                                <Box sx={{ display: "flex", height: rowHeight * 0.5 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", minWidth: "100px" }}>
                                        <span>{moment(task.checkoutEmpDate).format('MM/DD/YYYY')}</span>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ marginRight: 10 }}>{task.tas_title}</span>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", height: rowHeight * 0.5 }}>
                                    <Box sx={{ display: "flex", alignItems: "center", minWidth: "100px" }}>
                                        <span><Timelapse style={{ color : "#3FA7D6", marginRight : 5 }} fontSize='small' />{getDuration(task.empLocdate, task.checkoutEmpDate)}</span>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {address ? 
                                            <span style={{ marginRight: 10 }}>{address}</span> :
                                            <>
                                                {task.con_name ?
                                                    <>
                                                        <span style={{ lineHeight: "50%", marginRight: 10 }}><Person style={{ color : "#3FA7D6", marginRight : 2 }} fontSize='small' />
                                                            {task.con_name}
                                                        </span>
                                                        <span style={{ lineHeight: "50%", marginRight: 10 }}><Timelapse style={{ color : "#3FA7D6", marginRight : 2 }} fontSize='small' />
                                                            {getDuration(task.startDateofCall, task.endDateofCall)}
                                                        </span>
                                                        <span style={{ marginRight: 10 }}><Call style={{ color : "#3FA7D6", marginRight : 2 }} fontSize='small' />{phone}</span>
                                                    </> :
                                                    <span>No call information found</span>
                                                }
                                            </>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )
            }
        },
        {
            field: 'service_plan',
            headerName: 'Service Plan',
            width: 140,
            hide: false
        },
        {
            field: 'program',
            headerName: 'Program',
            width: 140,
            hide: false
        },
        {
            field: 'mt_submission_date',
            headerName: 'MT Submit Date',
            width: 140,
            hide: false,
            type: "date",
            valueGetter: (params) => {
                let date = null;

                if(!params.row.review_mt) {
                    return date;
                }
                params.row.review_mt[month].map(tas => {
                    if(tas.tas_mm_id) {
                        if(tas.submitted_on)
                            date = new Date(tas.submitted_on);
                    }
                })
                return date;
            },
            valueFormatter: (params) => params.value ? moment(params.value).format('MM/DD/YYYY') : null
        },
        {
            field: 'plan_approval_date',
            headerName: 'Plan Approval Date',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'plan_start_date',
            headerName: 'Plan Start Date',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'plan_end_date',
            headerName: 'Plan End Date',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'last_annual_visit',
            headerName: 'Last Annual Visit',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'last_mt_upload',
            headerName: 'Last MT Upload',
            type: "date",
            width: 140,
            hide: false
        }
    ]

    let rows = props.data.map((employee, i) => {
        return {
            hierarchy: [employee.emp_first_name + " " + employee.emp_last_name, employee.customer.cus_id],
            id: i,
            cus_id: employee.customer.cus_id,
            cus_name: employee.customer.cus_name,
            customers: employee.customer.cus_name,
            mt_period: moment(),
            review_mt: employee.customer,
            contact_details: employee.customer,
            service_plan: employee.customer.active_plan_version,
            program: employee.customer.plan_program_name,
            plan_approval_date: employee.customer.plan_approval_date ? moment(employee.customer.plan_approval_date).utc().format('MM/DD/YYYY') : null,
            plan_start_date: employee.customer.pro_starts_on ? moment(employee.customer.pro_starts_on).utc().format('MM/DD/YYYY') : null,
            plan_end_date: employee.customer.pro_ends_on ? moment(employee.customer.pro_ends_on).utc().format('MM/DD/YYYY') : null,
            last_annual_visit: employee.customer.LastAnnualVisitDate ? moment(employee.customer.LastAnnualVisitDate).utc().format('MM/DD/YYYY') : null,
            last_mt_upload: employee.customer.LastMMTUploadDate ? moment(employee.customer.LastMMTUploadDate).utc().format('MM/DD/YYYY') : null,
            emp_is_active: employee.emp_is_active
        }
    })

    rows = rows.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            JSON.stringify(t.hierarchy) === JSON.stringify(obj.hierarchy) && t.cus_id === obj.cus_id
        ))
    );

    rows = rows.filter((obj, index, self) =>
        obj.emp_is_active == true
    );
    
    const CustomToolbar = (params) => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <ToggleButton label="Group" value={isGrouped} onClick={() => setIsGrouped(!isGrouped)} />
                <BulkDownloadButton onClick={onBulkDownload} loading={isBulkDownloadLoading} />
                <SaveViewButton {...params} />
            </GridToolbarContainer>
        );
    }

    const onBulkDownload = async() => {
        const downloadURL = (data, fileName) => {
            const a = document.createElement('a');
            a.href = data;
            a.download = fileName;
            document.body.appendChild(a);
            a.style = 'display: none';
            a.click();
            a.remove();
        };

        setIsBulkDownloadLoading(true);

        const createReport = window.createReport;
        const numOfMonths = month === 'month3' ? 0 : month === 'month2' ? 1 : 2;
        const created_on = moment().subtract(numOfMonths, 'months').startOf('month').format('YYYY-MM-DD');

        let data = await axios.get(APIURL+"/monthlymonitoring/manager/all/"+props.emp_id, { params: {
            comp_id: props.user.comp_id,
            created_on: created_on 
        }});

        console.log(data)

        let zip = new JSZip();

        let mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        let fileName = props.user.emp_name.replace(' ', '_') + '_' + moment().subtract(numOfMonths, 'months').format('MMM_YYYY') + '_MT_Forms.zip';

        let createFiles = data.data?.map(async (mt) => {
            const report = await createReport({
                template: mt.mt_type === "Monthly" ? monthlyTemplate : quarterlyTemplate,
                data: JSON.parse(mt.data),
                cmdDelimiter: ['{', '}'],
                fixSmartQuotes: true,
                noSandbox: true
            });
            const blob = new Blob([report], { type: mimeType });
            if(templateString) {
                const values = {
                    customer_name: mt.cus_name.replace(' ', '_'),
                    month: moment(mt.mt_date).utc().format('MMM'),
                    year: moment(mt.mt_date).utc().format('YYYY'),
                    type: mt.mt_type
                };
                const replacedString = templateString.replace(/{(.*?)}/g, (match, key) => {
                    return values[key] || match; // Use the value from the 'values' object or keep the placeholder if not found
                });
                zip.file(replacedString + '.docx', blob);
            } else {
                zip.file(mt.cus_name.replace(' ', '_') + '_' + moment(mt.mt_date).format('MMM_YYYY') + `_${mt.mt_type}_Form.docx`, blob);
            }
            await axios.put(APIURL+"/monthlymonitoring/downloaded/" + mt.monthly_monitoring_id);
        })

        Promise.all(createFiles).then(() => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                const url = window.URL.createObjectURL(content);
                downloadURL(url, fileName, mimeType);
                setTimeout(() => {
                window.URL.revokeObjectURL(url);
                }, 100);
            }).then(() => {
                setIsBulkDownloadLoading(false);
            }).catch((err) => {
                setIsBulkDownloadLoading(false);
            });
        })
    }

    const getTreeDataPath = (row) => row.hierarchy;

    const groupingColDef = {
        headerName: 'Support Coordinators',
        valueFormatter: (params) => typeof params.id === 'number' ? '' : params.value
    };

    return (
        <Box sx={{ width: '100%', height: "calc(100vh - 150px)" }}>
            <ControlledDataGrid
                gridId={gridId}
                user={props.user}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'review_mt', sort: 'desc' }],
                    },
                }}
                treeData={isGrouped}
                getRowHeight={useCallback(({ model, densityFactor  }) => {
                    return rowHeight * densityFactor;
                }, [rowHeight])}
                rows={rows}
                columns={isSubscibedToTimeManagement ? columns : columns.filter(col => col.field !== 'contact_details')}
                components={{
                    Toolbar: CustomToolbar,
                }}
                pageSize={10}
                hideFooterSelectedRowCount={true}
                disableSelectionOnClick
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
                loading={props.loading}
                apiRef={apiRef}
                onLoad={(settings) => settings.hasOwnProperty('isGrouped') ? setIsGrouped(settings.isGrouped) : setIsGrouped(true)}
                onSave={(settings) => { settings.isGrouped = isGrouped }}
            />
            <ReviewMMT {...reviewData} show={showReview} handleClose={handleCloseReview} company_id={props.comp_id} user={props.user} reload={props.getEmployees} updateTaskStatus={props.updateTaskStatus}/>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={updated} autoHideDuration={6000} onClose={()=>{setUpdated(false)}}>
                <MuiAlert onClose={()=>{setUpdated(false)}} severity="success">
                   SUCCESSFULLY UPDATED FORM
                </MuiAlert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={()=>{setError(false)}}>
                <MuiAlert onClose={()=>{setError(false)}} severity="error">
                    ERROR OCCURRED WHILE UPDATING FORM
                </MuiAlert>
            </Snackbar>
      </Box>
    )
}

export default SupprotCoordinatorManagement;